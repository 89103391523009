import React, { useState, useEffect } from 'react';
import {
    useAnchorWallet,
    useConnection,
    useWallet,
} from '@solana/wallet-adapter-react';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';

import logo from "./logo.png";
import logo_text from "./logo_text.png";
import { Connection, LAMPORTS_PER_SOL, clusterApiUrl } from "@solana/web3.js";

const TopHeader: React.FC = () => {
    const { connection } = useConnection();
    let walletAddress = "";
    const [walletBalance, getMarbleTokenBalance] = useState(0);

    const wallet = useWallet();
    if (wallet.connected && wallet.publicKey) {
        walletAddress = wallet.publicKey.toString();
    }

    useEffect(() => {
        if (wallet.connected && wallet.publicKey) {
            getSolBalance(wallet.publicKey);
        }
    });

    async function getSolBalance(walletAdd: any) {
        const connectionSol = new Connection('https://mainnet.helius-rpc.com/?api-key=7b1a2f90-0eb6-490d-a49e-5a288c440f82');
        let bal = await connectionSol.getBalance(walletAdd);
        console.log(bal, (bal / LAMPORTS_PER_SOL), LAMPORTS_PER_SOL)
        getMarbleTokenBalance((bal / LAMPORTS_PER_SOL));
    }

    return (
        <>
            <div className="app-header">
              <div className="app-header-left">
                <div className="pos-1 box">
                  <img src={logo} className="App-logo" alt="logo" />
                </div>
                <div className="pos-2 box">
                  <img src={logo_text} className="App-logo" alt="logo" />
                </div>
              </div>
              <div className="app-header-right">
                <span className="button-wrapper">
                    <div className="wallet-bal">
                    { (wallet.connected && walletAddress) ? (
                          <span>{walletBalance} SOL</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <WalletModalProvider>
                        <WalletMultiButton />
                    </WalletModalProvider>
                </span>
                
              </div>
            </div>
        </>
    );
};

export default TopHeader;
