import React from "react";
import "./App.css";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import MyWallet from "./MyWallet";
import TopHeader from "./TopHeader";

import raydium_logo from "./raydium_logo.png";
import twitter_logo from "./twitter_logo.svg";
import telegram_logo from "./telegram_logo.svg";

function App() {
  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  const network = WalletAdapterNetwork.Mainnet;

  // You can also provide a custom RPC endpoint
  const endpoint = React.useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = React.useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>
        <div className="App">
          <header className="App-header">
            <TopHeader />
            <MyWallet />
            <p> UNLOCKING SOON..... </p>
          </header>
          <div className="footer">
            <a href="https://raydium.io/swap/"><img src={raydium_logo} className="footer-icon" alt="logo" /></a>
            <a href="https://twitter.com/alphasolalpha"><img src={twitter_logo} className="footer-icon" alt="logo" /></a>
            <a href="https://t.me/ALPHA_SOL"><img src={telegram_logo} className="footer-icon" alt="logo" /></a>
          </div>
        </div>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
