import React from 'react';
import key from "./key.png";

const MyWallet: React.FC = () => {
    return (
        <>
            <div className="multi-wrapper">
                <img src={key} className="App-inner" alt="logo" />
            </div>
        </>
    );
};

export default MyWallet;
